import React from 'react';
import Styleguide from '../components/style/styleguide';

class IndexPage extends React.Component {
  render() {
    return <Styleguide />;
  }
}

export default IndexPage;
