import * as React from 'react';

import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import {
  faBookmark,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCoffee,
  faCommentDots,
  faCube,
  faEllipsisH,
  faEllipsisV,
  faExclamation,
  faExclamationCircle,
  faMapMarkerAlt,
  faMinus,
  faPencilAlt,
  faPhoneAlt,
  faPlus,
  faSearch,
  faSlidersH,
  faSpinner,
  faStar,
  faTimes,
  faTruck,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Layout from '../layout';
import { Link } from 'gatsby';
// import Seo from "../components/seo"
import comImg4 from '../../images/not-sure.svg';

const Styleguide = () => (
  <Layout>
    <section className='common-section styleguide-sec'>
      <Container fluid>
        <Row>
          <Col xs={12} sm={12} md={6} lg={4} xl={4}>
            <Card className='comman-card'>
              <Card.Header>
                <h5 className='text-capitalize'>BG color + Text color</h5>
              </Card.Header>
              <Card.Body className='styleguide-file'>
                <h1>BG color</h1>
                <div className='mb10 bgc-purple'>background-color:#222C69;</div>
                <div className='mb10 bgc-red'>background-color:BA0000;</div>
                <div className='mb10 bgc-black'>background-color:#2F3032;</div>
                <div className='mb10 bgc-grey'>background-color:#B1B1B1;</div>
                <div className='mb10 bgc-yellow'>background-color:#F8AE3E;</div>
                <div className='mb10 bgc-green'>background-color:#16DECF;</div>
                <div className='mb10 bgc-l-blue'>background-color:#E3EEFF;</div>
                <div className='mb10 bgc-white'>background-color:#ffffff;</div>
                <hr />
                <h1>Color</h1>
                <div className='mb10 text-purple'>color:#222C69;</div>
                <div className='mb10 text-red'>color:BA0000;</div>
                <div className='mb10 text-black'>color:#2F3032;</div>
                <div className='mb10 text-grey'>color:#B1B1B1;</div>
                <div className='mb10 text-yellow'>color:#F8AE3E;</div>
                <div className='mb10 text-green'>color:#16DECF;</div>
                <div className='mb10 text-l-blue'>color:#E3EEFF;</div>
                <div className='mb10 text-white'>color:#ffffff;</div>
                <hr />
                <h6>Common Icon</h6>
                <br />
                <FontAwesomeIcon icon={faCoffee} />
                <FontAwesomeIcon icon={faChevronRight} />
                <FontAwesomeIcon icon={faChevronUp} />
                <FontAwesomeIcon icon={faChevronLeft} />
                <FontAwesomeIcon icon={faChevronDown} />
                <FontAwesomeIcon icon={faChevronRight} />
                <FontAwesomeIcon icon={faPlus} />
                <FontAwesomeIcon icon={faMinus} />
                <FontAwesomeIcon icon={faTimes} />
                <FontAwesomeIcon icon={faPencilAlt} />
                <FontAwesomeIcon icon={faEllipsisV} />
                <FontAwesomeIcon icon={faEllipsisH} />
                <FontAwesomeIcon icon={faBookmark} />
                <FontAwesomeIcon icon={faSearch} />
                <FontAwesomeIcon icon={faExclamationCircle} />
                <FontAwesomeIcon icon={faCommentDots} />
                <FontAwesomeIcon icon={faExclamation} />
                <FontAwesomeIcon icon={faCube} />
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                <FontAwesomeIcon icon={faSlidersH} />
                <FontAwesomeIcon icon={faSpinner} />
                <FontAwesomeIcon icon={faTruck} />
                <FontAwesomeIcon icon={faPhoneAlt} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faCheck} />
                <FontAwesomeIcon icon={faCheckSquare} />
                <FontAwesomeIcon icon={faCheckCircle} />
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} sm={12} md={6} lg={4} xl={4}>
            <Card className='comman-card'>
              <Card.Header>
                <h5 className='text-capitalize'>Form control</h5>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col xs={12} sm={12}>
                    <Form>
                      <Row>
                        <Col xs={12} sm={4}>
                          <Form.Group>
                            {/* <Form.Label>County</Form.Label> */}
                            <Form.Control as='select'>
                              <option selected disabled>
                                County
                              </option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                            </Form.Control>
                            <Form.Control.Feedback className='d-block'>
                              Looks good!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={4}>
                          <Form.Group className='zip-form-group'>
                            {/* <Form.Label>ZIP Code</Form.Label> */}
                            <Form.Control
                              type='tel'
                              className='location-filled'
                              placeholder='ZIP Code'
                            />
                            <Form.Control.Feedback
                              type='invalid'
                              className='d-block'
                            >
                              Please provide a valid ZIP Code.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={4}>
                          <Form.Group>
                            <Form.Control type='text' placeholder='Name' />
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={12}>
                          {/* <Form.File
                      id="custom-file-translate-scss"
                      label="Custom file input"
                      lang="en"
                      custom
                    /> */}
                          <Form.Group>
                            <Form.Label>Example textarea</Form.Label>
                            <Form.Control as='textarea' rows='3' />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className='text-center'>
                        <Col xs={6}>
                          <ul className='list-inline mt5'>
                            <li className='list-inline-item'>
                              <div className='radioOverride'>
                                <input
                                  checked=''
                                  id='test1'
                                  name='radio-group'
                                  type='radio'
                                />
                                <label htmlFor='test1' className='form-label'>
                                  Yes
                                </label>
                              </div>
                            </li>
                            <li className='list-inline-item'>
                              <div className='radioOverride'>
                                <input
                                  id='test2'
                                  name='radio-group'
                                  type='radio'
                                />
                                <label htmlFor='test2' className='form-label'>
                                  No
                                </label>
                              </div>
                            </li>
                          </ul>
                          <Button variant='btn btn-primary-custom-outline w-100'>
                            <span>Compare Plans Now!</span>
                          </Button>

                          <Button variant='btn btn-sm btn-yes'>
                            <span>Yes</span>
                          </Button>
                          <br className='mb30' />
                          <Button variant='btn btn-sm btn-no'>
                            <span>No</span>
                          </Button>

                          <Link className='btn btn-link-custom w-100'>
                            View 30+ Carriers
                          </Link>
                        </Col>
                        <Col xs={6}>
                          <div className='form-group-checkbox'>
                            <input
                              type='checkbox'
                              name='service[]'
                              value="I'm not sure"
                              id='comImgid4'
                            />
                            <label htmlFor='comImgid4'>
                              <span className='fa-check-span'>
                                <FontAwesomeIcon icon={faCheck} />
                              </span>
                              <img
                                src={comImg4}
                                alt="I'm not sure"
                                className='com-img'
                              />
                              <p className='p18'>I&apos;m not sure</p>
                              <p className='p16'>Show me all</p>
                            </label>
                          </div>
                        </Col>
                        <Col xs={6}>
                          <Button variant='btn btn-primary-custom w-100'>
                            <span>Compare Plans Now!</span>
                          </Button>
                        </Col>
                        <Col xs={6}>
                          <Button variant='btn btn-danger-custom w-100'>
                            <span>Let&apos;s Talk</span>
                          </Button>
                        </Col>
                        <Col xs={12} className='mt30'>
                          <Button variant='btn btn-danger-custom-outline w-100'>
                            <span>View Plan Chart</span>
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} xl={4}>
            <Card className='comman-card'>
              <Card.Header>
                <h5 className='text-capitalize'>Typography</h5>
              </Card.Header>
              <Card.Body>
                <div className='h1'>
                  h1, .h1 - font-weight: 800; font-size: 38px;
                </div>
                <div className='h2'>
                  h2, .h2 - font-weight: bold; font-size: 36px;
                </div>
                <div className='h3'>
                  h3, .h3 - font-weight: bold; font-size: 32px;
                </div>
                <div className='h4'>
                  h4, .h4 - font-weight: bold; font-size: 24px;
                </div>
                <div className='p20'>
                  .p20 - font-weight: normal; font-size: 20px;
                </div>
                <div className='p18'>
                  .p18 - font-weight: normal; font-size: 18px;
                </div>
                <div className='p16'>
                  .p16 - font-weight: normal; font-size: 16px;
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* <Link to="/">Go back to the homepage</Link> */}
    </section>
  </Layout>
);

export default Styleguide;
